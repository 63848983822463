.login-form {
    width: 340px;
    margin: 170px auto;
}
.login-form form {
    margin-bottom: 15px;
    background:#692062;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
    color:white;
}
.login-form h2 {
    margin: 0 0 15px;
}
.form-control, .btn {
    min-height: 38px;
    border-radius: 2px;
}
.btn {        
    font-size: 15px;
    font-weight: bold;
}

.container { 
    /*background: url('officebg.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
   }
   .card label{
       color:black;
   }